// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-gif-1-js": () => import("../src/pages/gif1.js" /* webpackChunkName: "component---src-pages-gif-1-js" */),
  "component---src-pages-gif-2-js": () => import("../src/pages/gif2.js" /* webpackChunkName: "component---src-pages-gif-2-js" */),
  "component---src-pages-gif-3-js": () => import("../src/pages/gif3.js" /* webpackChunkName: "component---src-pages-gif-3-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-surprise-js": () => import("../src/pages/surprise.js" /* webpackChunkName: "component---src-pages-surprise-js" */)
}

